import React, { useState } from 'react';

import { Box, ListItemButton, ListItemText, Menu } from '@mui/material';
import { NavLink } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import gradientWithComma from 'utils/gradientWithComma';
import { usePlatformConfigContext } from 'contexts/PlatformConfigContext';
import AuthorisedRoutes from '../../../AuthorisedRoutes';
import { Section } from './sections';
import WithPermissions from '../Permissions';
import useGetCurrentSection from './hooks/useGetSection';

interface IProps {
  section: Section;
}

type IAppbarLinkProps = Pick<
  Section,
  'name' | 'path' | 'tab_access' | 'permission'
> & {
  hasSubMenus?: boolean;
  isSubTabSelected?: boolean;
};

export const AppbarLink = ({
  name,
  tab_access,
  path,
  hasSubMenus,
  permission,
  isSubTabSelected,
}: IAppbarLinkProps & { hasSubMenus?: boolean }) => {
  const platformConfig = usePlatformConfigContext();

  return (
    <WithPermissions permission={permission}>
      <AuthorisedRoutes tab_name={tab_access} Appbar>
        <NavLink
          to={path}
          style={({ isActive }) => ({
            display: 'block',
            background:
              isActive || isSubTabSelected
                ? platformConfig.navbar_hover_color
                : 'transparent',
          })}
          onClick={(e) => {
            if (hasSubMenus) e.preventDefault();
          }}
        >
          <ListItemButton>
            <ListItemText primary={name} />
            {hasSubMenus && <KeyboardArrowDownIcon />}
          </ListItemButton>
        </NavLink>
      </AuthorisedRoutes>
    </WithPermissions>
  );
};

const AppbarMenuItem = ({ section }: IProps) => {
  const curSection = useGetCurrentSection('section');

  const platformConfig = usePlatformConfigContext();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  if (section.subTabs) {
    const isSubTabSelected = section.subTabs.some(
      (subTab) => subTab.path === curSection,
    );

    return (
      <WithPermissions
        permission={section.subTabs.map((subTab) => subTab.permission)}
      >
        <Box onClick={(e) => setAnchorEl(e.currentTarget)}>
          <AppbarLink
            isSubTabSelected={isSubTabSelected}
            hasSubMenus
            {...section}
          />
        </Box>

        <Menu
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          open={!!anchorEl}
          sx={{
            '.MuiPaper-root': {
              background: gradientWithComma(platformConfig.navbar_color),
              minWidth: '12rem',
            },
          }}
        >
          {section.subTabs.map((subTab) => (
            <Box key={subTab.id} onClick={() => setAnchorEl(null)}>
              <AppbarLink {...subTab} />
            </Box>
          ))}
        </Menu>
      </WithPermissions>
    );
  }

  return <AppbarLink {...section} />;
};

export default AppbarMenuItem;
