// Based on user notification settings, send notification to user
import { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

import useHasUserInteractedWithDom from 'hooks/useHasUserInteractedWithDom';
import {
  useNotificationsMarkAsReadByRecordIdCreateMutation,
  useUsersCurrentRetrieveQuery,
} from 'services/api/general';
import { NotificationType } from './types';

const useSendNotification = () => {
  const hasUserInteractedWithDom = useHasUserInteractedWithDom();

  const { data: curUser } = useUsersCurrentRetrieveQuery();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [triggerMarkNotificationAsRead] =
    useNotificationsMarkAsReadByRecordIdCreateMutation();

  const handleViewNotificationClick = (url: string, record_id: string) => {
    navigate(url);
    triggerMarkNotificationAsRead({
      markNotificationsAsReadRequest: {
        record_id,
      },
    });
  };

  const handleSendNotification = useCallback(
    (event: NotificationType) => {
      let url: string | undefined;
      if (event.metadata?.tab === 'alert') {
        url = `/alerts/summary/${event.metadata.record_id}`;
      }

      // If user hasn't interacted with the document and play a sound, that will produce an error.
      // This check should prevent the error.
      if (hasUserInteractedWithDom) {
        const audio = new Audio('/sounds/notification-sound.mp3');
        audio.play();
      }

      enqueueSnackbar(event.body, {
        style: {
          cursor: url ? 'pointer' : 'default',
        },
        variant: 'info',
        autoHideDuration: 10000,
        onClick: () => {
          if (url && event.metadata)
            handleViewNotificationClick(url, event.metadata?.record_id);
        },
      });

      if (
        Notification.permission === 'granted' &&
        curUser?.notification_settings.system_notifications
      ) {
        const notification = new Notification(event.body);
        notification.onclick = () => {
          window.focus();
          if (url && event.metadata) {
            handleViewNotificationClick(url, event.metadata.record_id);
          }
        };
      }
    },
    [curUser, hasUserInteractedWithDom],
  );

  return handleSendNotification;
};

export default useSendNotification;
