import React from 'react';

import { Navigate } from 'react-router';
import { Tabs } from 'ts_shared';

import NoAccess from 'components/UnauthorizedAccess';
import { useUsersCurrentRetrieveQuery } from 'services/api/general';
import LoadingScreen from 'components/Elements/LoadingScreen';

interface AuthorisedRoutesProps {
  children: JSX.Element;
  tab_name?: keyof Tabs | (keyof Tabs)[];
  Appbar?: boolean;
  Sidebar?: boolean;
}

const AuthorisedRoutes = ({
  children,
  tab_name,
  Appbar = false,
  Sidebar = false,
}: AuthorisedRoutesProps) => {
  const { data, isLoading } = useUsersCurrentRetrieveQuery();
  const tabAccess = data?.tab_access;

  if (!tab_name) {
    return children;
  }

  // Check if the user has access to the tab
  const hasTabAccess = Array.isArray(tab_name)
    ? tabAccess &&
      tab_name.some((name) => tabAccess[name as keyof typeof tabAccess])
    : tabAccess && tabAccess[tab_name as keyof typeof tabAccess];

  if (Appbar || Sidebar) return hasTabAccess ? children : null;

  if (isLoading) return null;

  return hasTabAccess ? children : <NoAccess />;
};

interface ProtectedRouteProps {
  children: JSX.Element;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const token = localStorage.getItem('token');
  const { data: user, isLoading } = useUsersCurrentRetrieveQuery();

  if (isLoading) return <LoadingScreen />;

  if (!token) {
    return <Navigate replace to="/login" />;
  }

  if (user?.account_state !== 'active') {
    return <Navigate replace to={`/auth/activate-account/${user?.id}`} />;
  }

  return children;
};

export default AuthorisedRoutes;
