import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en';
import translationZH from './locales/zh';
import translationJA from './locales/ja';

const resources = {
  en: translationEN,
  zh: translationZH,
  ja: translationJA,
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
