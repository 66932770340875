import React, { useMemo } from 'react';

import { v4 as uuidv4 } from 'uuid';
import i18n from 'i18n';
import HomeIcon from '@mui/icons-material/Home';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SettingsIcon from '@mui/icons-material/Settings';
import RouteIcon from '@mui/icons-material/Route';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import { Tabs } from 'ts_shared';

import AlertPermissions from 'tabs/alerts/AlertPermissions';
import FrPermissions from 'tabs/facialRecognition/FrPermissions';
import VaPermissions from 'tabs/vehicleAccess/VaPermissions';
import VmPermissions from 'tabs/vehicleManagement/VmPermissions';
import { useUsersCurrentRetrieveQuery } from 'services/api/general';
import UsecaseUsagePermissions from 'tabs/usecaseUsage/UsecaseUsagePermissions';
import CommonPermissions from 'permissions/CommonPermissions';

const tn = (key: string, defaultText: string) => {
  return i18n.t(`common.${key}`, defaultText, { ns: 'common' });
};

interface Route {
  name: string;
  icon: JSX.Element;
  url: string;
  permission: string;
}
export interface Section {
  id: string;
  name: string;
  path: string;
  tab_access?: keyof Tabs;
  permission: string;
  routes: Route[];
  subTabs?: Omit<Section[], 'subTabs'>;
}

export type Sections = Section[];

const sections: Sections = [
  {
    id: uuidv4(),
    name: tn('alerts', 'Alerts'),
    path: 'alerts',
    tab_access: 'alerts',
    permission: AlertPermissions.VIEW_ALERT_TAB,
    routes: [
      {
        name: tn('cameras', 'Cameras'),
        icon: <VideoCameraBackIcon />,
        url: 'cameras',
        permission: AlertPermissions.VIEW_ALERT_CAMERA_STREAMS,
      },
      {
        name: tn('dashboard', 'Dashboard'),
        icon: <HomeIcon />,
        url: 'dashboard',
        permission: AlertPermissions.VIEW_ALERT_DASHBOARD,
      },
      {
        name: tn('summary', 'Summary'),
        icon: <LibraryBooksIcon />,
        url: 'summary',
        permission: AlertPermissions.VIEW_ALERT_SUMMARY,
      },
      {
        name: tn('configuration', 'Configuration'),
        icon: <DisplaySettingsIcon />,
        url: 'configuration',
        permission: AlertPermissions.VIEW_ALERT_CONFIGURATION,
      },
    ],
  },
  {
    id: uuidv4(),
    name: tn('peopleRecognition', 'People Recognition'),
    path: 'people',
    tab_access: 'people_recognition',
    permission: FrPermissions.VIEW_FR_TAB,
    routes: [
      {
        name: tn('cameras', 'Cameras'),
        icon: <VideoCameraBackIcon />,
        url: 'cameras',
        permission: AlertPermissions.VIEW_ALERT_CAMERA_STREAMS,
      },
      {
        name: tn('dashboard', 'Dashboard'),
        icon: <HomeIcon />,
        url: 'dashboard',
        permission: FrPermissions.VIEW_FR_DASHBOARD,
      },
      {
        name: tn('trail', 'Trail'),
        icon: <RouteIcon />,
        url: 'trail',
        permission: FrPermissions.VIEW_FR_TRAIL,
      },
      {
        name: tn('userManagement', 'User Management'),
        icon: <ManageAccountsIcon />,
        url: 'user-management',
        permission: FrPermissions.VIEW_FR_USER_MANAGEMENT,
      },
      {
        name: tn('configuration', 'Configuration'),
        icon: <DisplaySettingsIcon />,
        url: 'configure',
        permission: FrPermissions.VIEW_FR_CONFIG,
      },
      {
        name: tn('settings', 'Settings'),
        icon: <SettingsIcon />,
        url: 'people-settings',
        permission: FrPermissions.VIEW_FR_SETTINGS,
      },
    ],
  },
  {
    id: uuidv4(),
    name: tn('vehicularAccess', 'Vehicular Access'),
    path: 'vehicles',
    tab_access: 'vehicular_access',
    permission: VaPermissions.VIEW_VA_TAB,
    routes: [
      {
        name: tn('cameras', 'Cameras'),
        icon: <VideoCameraBackIcon />,
        url: 'cameras',
        permission: AlertPermissions.VIEW_ALERT_CAMERA_STREAMS,
      },
      {
        name: tn('dashboard', 'Dashboard'),
        icon: <HomeIcon />,
        url: 'dashboard',
        permission: VaPermissions.VIEW_VA_DASHBOARD,
      },
      {
        name: tn('manage', 'Manage'),
        icon: <PlaylistAddCheckCircleIcon />,
        url: 'record-management',
        permission: VaPermissions.VIEW_VA_MANAGEMENT,
      },
      {
        name: tn('configuration', 'Configuration'),
        icon: <DisplaySettingsIcon />,
        url: 'configure',
        permission: VaPermissions.VIEW_VA_CONFIGURATION,
      },
    ],
  },
  {
    id: uuidv4(),
    name: tn('inspection', 'Inspection'),
    path: 'inspection',
    tab_access: 'inspection',
    permission: '',
    routes: [
      {
        name: tn('dashboard', 'Dashboard'),
        icon: <HomeIcon />,
        url: 'dashboard',
        permission: '',
      },
    ],
  },

  {
    id: uuidv4(),
    name: 'Vehicle Management',
    path: 'vehicle-management',
    tab_access: 'vehicle_management',
    permission: VmPermissions.VIEW_VM_TAB,
    routes: [
      {
        name: 'Dashboard',
        icon: <HomeIcon />,
        url: 'dashboard',
        permission: VmPermissions.VIEW_VM_DASHBOARD,
      },
      {
        name: 'Configuration',
        icon: <DisplaySettingsIcon />,
        url: 'configure',
        permission: VmPermissions.VIEW_VM_CONFIGURATION,
      },
      {
        name: 'Manage',
        icon: <PlaylistAddCheckCircleIcon />,
        url: 'record-management',
        permission: VmPermissions.VIEW_VM_MANAGEMENT,
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Camera Health',
    path: 'camera-health',
    tab_access: 'camera_health_check',
    permission: '',
    routes: [
      {
        name: 'Dashboard',
        icon: <HomeIcon />,
        url: 'dashboard',
        permission: '',
      },
    ],
  },

  {
    id: uuidv4(),
    name: 'Crowd Surveillance',
    path: 'footfall',
    tab_access: 'footfall',
    permission: '',
    subTabs: [
      {
        id: uuidv4(),
        name: 'Footfall',
        path: 'footfall',
        permission: '',
        tab_access: 'footfall',
        routes: [
          {
            name: 'Dashboard',
            icon: <HomeIcon />,
            url: 'dashboard',
            permission: '',
          },
          {
            name: 'Configuration',
            icon: <DisplaySettingsIcon />,
            url: 'configuration',
            permission: AlertPermissions.VIEW_ALERT_CONFIGURATION,
          },
        ],
      },
    ],
    routes: [],
  },
  {
    id: uuidv4(),
    name: 'Productivity',
    tab_access: 'workhour',
    permission: '',
    path: 'workhour-tracking',
    subTabs: [
      {
        id: uuidv4(),
        name: 'Workhour Tracking',
        permission: '',
        tab_access: 'workhour',
        path: 'workhour-tracking',
        routes: [
          {
            name: 'Dashboard',
            icon: <HomeIcon />,
            url: 'dashboard',
            permission: '',
          },
          {
            name: 'Configuration',
            icon: <DisplaySettingsIcon />,
            url: 'configuration',
            permission: AlertPermissions.VIEW_ALERT_CONFIGURATION,
          },
        ],
      },
    ],
    routes: [],
  },
  {
    id: uuidv4(),
    name: 'Operations',
    permission: '',
    path: '',
    subTabs: [
      {
        id: uuidv4(),
        name: 'Usecase Usage',
        permission: UsecaseUsagePermissions.VIEW_USECASE_USAGE_TAB,
        path: 'usecase-usage',
        routes: [
          {
            name: 'Dashboard',
            icon: <HomeIcon />,
            url: 'dashboard',
            permission: '',
          },
        ],
      },
      {
        id: uuidv4(),
        name: 'Control Panel',
        path: 'control-panel',
        permission: CommonPermissions.WRITE_OPERATIONS_PAGE,
        routes: [
          {
            name: 'Dashboard',
            icon: <HomeIcon />,
            url: 'dashboard',
            permission: '',
          },
        ],
      },

      {
        id: uuidv4(),
        name: 'Vendor',
        path: 'vendor',
        permission: 'superuser',
        routes: [
          {
            name: 'Dashboard',
            icon: <HomeIcon />,
            url: 'dashboard',
            permission: 'superuser',
          },
        ],
      },
    ],
    routes: [],
  },
];

export const useAuthorisedSections = () => {
  // Only the sections that current user has access to

  const { data: curUser } = useUsersCurrentRetrieveQuery();

  return useMemo(() => {
    return sections
      .filter((section) =>
        section.tab_access ? curUser?.tab_access[section.tab_access] : true,
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [curUser]);
};

export default sections;
