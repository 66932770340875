import React, {
  createRef,
  ReactElement,
  useEffect,
  useState,
  type HTMLInputTypeAttribute,
} from 'react';

import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  ButtonBase,
  Grid,
  MenuItem,
  Stack,
  Switch,
  Checkbox,
  type BaseTextFieldProps,
  type BoxProps,
  type FormControlLabelProps,
  type TextFieldProps,
  type SwitchProps,
  type SelectProps,
  type CheckboxProps,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers-pro';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import {
  UseControllerProps,
  useController,
  FieldValues,
  UseFormGetFieldState,
} from 'react-hook-form';

import ErrorMessage from 'libs/react-hook-form/ErrorMessage';
import type { S3SignedRetrieveApiArg } from 'services/api/ailyssaApi';
import { useSnackbar } from 'notistack';
import {
  CustomDropdownField,
  CustomFormControlLabel,
  CustomTextField,
} from './CustomFormControls';
import FormFieldDescription from './FormFieldDescription';
import { useGetS3Url, useHandleUploadFile } from './commonFormsHooks';
import CustomModel from '../CustomModel';
import FieldSet from './FieldSet';

interface FieldRendererProps<T extends FieldValues>
  extends UseControllerProps<T> {
  label?: string;
  subtitle?: string;
}

interface SwitchFieldProps<T extends FieldValues>
  extends Omit<FieldRendererProps<T>, 'label'> {
  validationRequiredFields?: string[];
  getFieldState?: UseFormGetFieldState<FieldValues>;
  switchProps?: SwitchProps;
  label: FormControlLabelProps['label'];
}

type DropdownItemType = {
  label: string | number;
  value: string | number;
};

interface DropdownFieldRendererProps<T extends FieldValues>
  extends Omit<FieldRendererProps<T>, 'type'> {
  items: DropdownItemType[];
  multiple?: boolean;
  required?: boolean;
  selectProps?: SelectProps;
}

interface FileUploadFieldRendererProps<T extends FieldValues>
  extends Omit<FieldRendererProps<T>, 'type'> {
  context: S3SignedRetrieveApiArg['context'];
  onChange?: (url: string) => void;
  required?: boolean;
  defaultImage?: string;
}

export interface TextFieldRendererProps<T extends FieldValues>
  extends FieldRendererProps<T> {
  type: HTMLInputTypeAttribute;
  inputProps?: BaseTextFieldProps['inputProps'];
  containerStyles?: BoxProps['sx'];
  required?: boolean;
  disableTextField?: boolean;
  textFieldProps?: TextFieldProps;
  fieldAction?: ReactElement;
}

type CustomFormControlLabelProps = Omit<
  FormControlLabelProps,
  'label' | 'title' | 'control'
>;

const imageTypes = ['image/jpg', 'image/jpeg', 'image/png'];

const TextFieldRenderer = <T extends FieldValues>({
  type,
  label,
  subtitle,
  inputProps,
  containerStyles,
  required,
  textFieldProps = {},
  disableTextField = false,
  fieldAction,
  ...props
}: TextFieldRendererProps<T>) => {
  const {
    field: { onChange, value, ...otherFieldProps },
    formState: { errors },
  } = useController({
    ...props,
  });

  const { disabled } = props;

  return (
    <Box sx={{ display: 'grid', height: '100%', ...containerStyles }}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Box>
          <FormFieldDescription
            required={required}
            title={label}
            subtitle={subtitle}
          />
        </Box>
        <Box>{fieldAction}</Box>
      </Stack>
      <FieldSet viewOnly={disableTextField}>
        <CustomTextField
          style={{
            width: '100%',
            backgroundColor: disabled ? 'lightgrey' : 'inherit',
          }}
          size="small"
          type={type}
          inputProps={inputProps}
          {...otherFieldProps}
          value={value ?? ''}
          onChange={({ target: { value: inputValue } }) => {
            if (type === 'number' && inputValue !== '')
              onChange(Number(inputValue));
            else onChange(inputValue);
          }}
          {...textFieldProps}
        />
      </FieldSet>
      <ErrorMessage errors={errors} name={props.name} />
    </Box>
  );
};

const TimeFieldRenderer = <T extends FieldValues>({
  label,
  subtitle,
  required,
  ...props
}: Omit<FieldRendererProps<T>, 'type'> & { required?: boolean }) => {
  const {
    field: { ref, value, onChange },
    formState: { errors },
  } = useController(props);

  return (
    <Box sx={{ display: 'grid', height: '100%' }}>
      <Box>
        <FormFieldDescription
          required={required}
          title={label}
          subtitle={subtitle}
        />
      </Box>
      <TimePicker
        ref={ref}
        value={value && new Date(`2012-10-10T${value}`)}
        ampm={false}
        onChange={(date) => {
          const time = !date ? '' : date.toTimeString().substring(0, 8);
          onChange(time);
        }}
        slotProps={{
          textField: {
            InputProps: {
              size: 'small',
            },
          },
        }}
      />
      <ErrorMessage errors={errors} name={props.name} />
    </Box>
  );
};

const SwitchFieldRenderer = <T extends FieldValues>({
  label,
  subtitle,
  formControlLabelProps = {},
  getFieldState,
  switchProps,
  ...props
}: Omit<SwitchFieldProps<T>, 'type'> & {
  formControlLabelProps?: CustomFormControlLabelProps;
}) => {
  const { field } = useController(props);

  return (
    <CustomFormControlLabel
      control={
        <Switch
          size="small"
          checked={field.value || false}
          {...field}
          {...switchProps}
          id={switchProps?.name}
        />
      }
      label={label || ''}
      title={subtitle}
      {...formControlLabelProps}
    />
  );
};

const SelectFieldRenderer = <T extends FieldValues>({
  label,
  items,
  subtitle,
  multiple,
  required,
  selectProps,
  ...props
}: DropdownFieldRendererProps<T>) => {
  const {
    field: { value: currValue, ...others },
    formState: { errors },
  } = useController(props);

  let adjustedCurrValue: string | number | undefined | (string | number)[] =
    currValue;

  if (!currValue && currValue !== 0) {
    adjustedCurrValue = multiple ? [] : '';
  }

  return (
    <Stack>
      <FormFieldDescription
        required={required}
        title={label}
        subtitle={subtitle}
      />
      <CustomDropdownField
        size="small"
        value={adjustedCurrValue}
        {...others}
        multiple={multiple}
        {...selectProps}
      >
        {items.map(({ label: itemLabel, value }) => (
          <MenuItem key={value} value={value}>
            {itemLabel}
          </MenuItem>
        ))}
      </CustomDropdownField>
      <ErrorMessage errors={errors} name={props.name} />
    </Stack>
  );
};

const S3FileField = <T extends FieldValues>({
  label,
  context,
  subtitle,
  onChange,
  required,
  ...props
}: FileUploadFieldRendererProps<T>) => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const { field } = useController(props);
  const ref = createRef<HTMLInputElement>();
  const handleGetS3Url = useGetS3Url();
  const handleUpload = useHandleUploadFile();

  useEffect(() => {
    const { value } = field;

    if (!value || !value.includes('http') || !onChange) {
      return;
    }

    onChange(field.value);
  }, [field.value]);

  const onFilesChange = () => {
    if (!ref.current || !ref.current.files || ref.current.files.length === 0) {
      return;
    }

    const file = ref.current.files[0];

    if (!imageTypes.some((type) => file.type === type)) {
      // Invalid file type
      enqueueSnackbar(t('common.fileTypeInvalid', 'File type is invalid.'), {
        variant: 'error',
      });
      return;
    }
    handleGetS3Url(file.name, context).then((res) => {
      handleUpload(file, res.url)
        .then(() => {
          if (onChange) {
            onChange(res.download_url);
          }

          field.onChange(res.key);

          enqueueSnackbar(
            `${file.name} ${t(
              'common.uploadSuccess',
              'uploaded successfully',
            )}`,
            {
              variant: 'success',
            },
          );
        })
        .catch((err) => {
          enqueueSnackbar(err.message, { variant: 'error' });
        });
    });
  };

  return (
    <Box sx={{ display: 'grid' }}>
      <FormFieldDescription
        required={required}
        title={label}
        subtitle={subtitle}
      />
      <Button
        startIcon={<CloudUploadIcon />}
        onClick={() => ref.current?.click()}
      >
        {t('common.upload', 'Upload')}
        <input
          ref={ref}
          type="file"
          accept={imageTypes.join(', ')}
          hidden
          onInput={onFilesChange}
        />
      </Button>
    </Box>
  );
};

const S3ImageField = <T extends FieldValues>({
  defaultImage,
  ...props
}: FileUploadFieldRendererProps<T>) => {
  const { t } = useTranslation('common');
  const {
    formState: { errors },
  } = useController({ control: props.control, name: props.name });

  const [imageUrl, setImageUrl] = useState<string>(defaultImage || '');

  return (
    <Grid container spacing={1} sx={{ alignItems: 'center' }}>
      <Grid item xs>
        <S3FileField onChange={setImageUrl} {...props} />
      </Grid>
      <Grid item>
        {imageUrl && (
          <CustomModel
            title={t('common.imagePreview', 'Image Preview')}
            noFooter
            triggerButton={
              <ButtonBase>
                <img
                  width={60}
                  height={60}
                  src={imageUrl || defaultImage}
                  alt={`${props.name}`}
                  style={{ border: '1px solid' }}
                />
              </ButtonBase>
            }
            maxWidth={false}
            dialogContentProps={{
              sx: { display: 'flex', justifyContent: 'center' },
            }}
          >
            <img src={imageUrl || defaultImage} alt={`${props.name}`} />
          </CustomModel>
        )}
      </Grid>
      <Grid xs={12} item>
        <ErrorMessage name={props.name} errors={errors} />
      </Grid>
    </Grid>
  );
};

interface CheckboxFieldRendereProps<T extends FieldValues>
  extends FieldRendererProps<T> {
  checkboxProps?: CheckboxProps;
  formControlLabelProps?: CustomFormControlLabelProps;
  label: string;
}

const CheckboxFieldRenderer = <T extends FieldValues>({
  checkboxProps,
  formControlLabelProps,
  label,
  ...props
}: CheckboxFieldRendereProps<T>) => {
  const { field } = useController(props);

  return (
    <CustomFormControlLabel
      control={
        <Checkbox
          {...checkboxProps}
          {...field}
          checked={field.value || false}
        />
      }
      label={label}
      {...formControlLabelProps}
    />
  );
};

export {
  TextFieldRenderer,
  SwitchFieldRenderer,
  SelectFieldRenderer,
  S3FileField,
  TimeFieldRenderer,
  S3ImageField,
  CheckboxFieldRenderer,
};
