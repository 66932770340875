import { subWeeks } from 'date-fns';
import { type DateRange } from '@mui/x-date-pickers-pro';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TimezoneName } from 'countries-and-timezones';

export type DateRangeState = {
  range: DateRange<Date>;
};

const initialState: {
  selectedDateRange: DateRange<Date>;
  timezone: TimezoneName;
} = {
  selectedDateRange: [
    subWeeks(new Date(), 2), // Subtract 2 weeks from the current date
    new Date(new Date().setHours(23, 59, 0, 0)),
  ],
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as TimezoneName,
};

const dateRangeFilterSlice = createSlice({
  name: 'dateRange2',
  initialState,
  reducers: {
    selectDateRange: (state, action: PayloadAction<DateRange<Date>>) => {
      state.selectedDateRange = action.payload;
    },
    selectTimezone: (state, action: PayloadAction<TimezoneName | null>) => {
      state.timezone =
        action.payload ||
        (Intl.DateTimeFormat().resolvedOptions().timeZone as TimezoneName);
    },
  },
});

export const { selectDateRange, selectTimezone } = dateRangeFilterSlice.actions;

export default dateRangeFilterSlice.reducer;
