import { useMemo, useState } from 'react';

import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';
import { useClipboard } from '@mantine/hooks';
import { useSnackbar } from 'notistack';

import { type RootState } from 'store';
import { endOfDay, formatISO, startOfDay } from 'date-fns';

import { useGetSelectedOperationsFilterOpts } from 'services/filters/operationsFilter/operationsFilter';
import {
  getSelectedTriggerIds,
  getSelectedTriggers,
} from 'services/filters/triggers/triggersFilterSlice';
import {
  getSelectedStatuses,
  getSelectedStatusIds,
} from 'services/filters/alertStatuses';
import { getSelectedFollowedBookmarked } from 'services/filters/alertFollowBookmark';
import { getSelectedAssignees } from 'services/filters/alertAssignees';

const useOpen = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return [open, handleOpen, handleClose] as const;
};

const useCustomMediaQuery = (breakpoint: Breakpoint) => {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.down(breakpoint));
};

const useDateRange = () => {
  const { selectedDateRange, timezone } = useSelector(
    (state: RootState) => state.dateRange2,
  );

  const startDate = (selectedDateRange && selectedDateRange[0]) || undefined;
  const endDate = (selectedDateRange && selectedDateRange[1]) || undefined;

  const isoStartDate = startDate && formatISO(startOfDay(startDate));
  const isoEndDate = endDate && formatISO(endOfDay(new Date(endDate)));

  const timezonedStartDate = moment(isoStartDate)
    .parseZone()
    .tz(timezone || Intl.DateTimeFormat().resolvedOptions().timeZone, true)
    .toISOString(true);

  const timezonedEndDate =
    isoEndDate &&
    moment(isoEndDate)
      .parseZone()
      .tz(timezone || Intl.DateTimeFormat().resolvedOptions().timeZone, true)
      .toISOString(true);

  return {
    startDate,
    endDate,
    isoStartDate,
    isoEndDate,
    timezonedStartDate,
    timezonedEndDate,
    timezone,
  };
};

const useAllFilters = () => {
  const {
    startDate,
    endDate,
    isoStartDate,
    isoEndDate,
    timezonedStartDate,
    timezonedEndDate,
  } = useDateRange();

  const triggerIds = useSelector(getSelectedTriggerIds);
  const triggers = useSelector(getSelectedTriggers);
  const statusIds = useSelector(getSelectedStatusIds);
  const statuses = useSelector(getSelectedStatuses);
  const selectedOperationsFilterOpts = useGetSelectedOperationsFilterOpts();
  const { bookmarked, followed } = useSelector(getSelectedFollowedBookmarked);
  const selectedAssignees = useSelector(getSelectedAssignees);
  const timezone = useSelector((state: RootState) => state.dateRange2.timezone);

  const selectedFollowedBookmarkedOpts = useMemo(
    () => bookmarked.concat(followed),
    [bookmarked, followed],
  );

  return {
    startDate,
    endDate,
    triggerIds,
    triggers,
    statusIds,
    statuses,
    isoStartDate,
    isoEndDate: isoEndDate as string | undefined,
    selectedOperationsFilterOpts,
    selectedFollowedBookmarkedOpts,
    assignees: selectedAssignees,
    timezone,
    timezonedStartDate,
    timezonedEndDate,
  };
};

const useCopyWithNotification = (timeout?: number) => {
  const { copy, copied, error, reset } = useClipboard({ timeout });
  const { enqueueSnackbar } = useSnackbar();

  const copyAndNotify = (text: string) => {
    copy(text);

    if (error) {
      enqueueSnackbar('Error copying to clipboard', { variant: 'error' });
    } else {
      enqueueSnackbar('Copied to clipboard', { variant: 'success' });
    }
  };

  return { copyAndNotify, copied, error, reset };
};

export {
  useOpen,
  useDateRange,
  useAllFilters,
  useCustomMediaQuery,
  useCopyWithNotification,
};
